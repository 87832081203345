import { Enumeration, GetEnumerationLiterals } from '@bp/shared/models/core/enum';

import { EventPayload } from './event-payload.type';

export type BloxEventLiterals = GetEnumerationLiterals<typeof BloxEvent>;

export type BloxStateEventPayload = {
	empty: boolean;
	valid: boolean;
	invalid: boolean;
	dirty: boolean;
	pristine: boolean;
	touched: boolean;
	untouched: boolean;
	error: string | null;
	[key: string]: any;
};

export class BloxEvent<T extends EventPayload> extends Enumeration {

	static init = new BloxEvent();

	static rendered = new BloxEvent();

	static ready = new BloxEvent();

	static blur = new BloxEvent<BloxStateEventPayload>();

	static focus = new BloxEvent<BloxStateEventPayload>();

	static change = new BloxEvent<BloxStateEventPayload>();

	static validate = new BloxEvent<BloxStateEventPayload>();

	static setPaymentCardBrand = new BloxEvent<{ valid: boolean; error?: string }>();

	static destroy = new BloxEvent();

	payloadSchema!: T;

}
