import { DescribableEnumeration } from '@bp/shared/models/core/enum';

export class CheckoutType extends DescribableEnumeration {

	static regular = new CheckoutType();

	static virtualTerminal = new CheckoutType();

	static paywith = new CheckoutType();

	static payout = new CheckoutType();

	static external = new CheckoutType();

	static paymentLink = new CheckoutType();

	static pos = new CheckoutType();

	static blox = new CheckoutType(
		undefined,
		'Checkout in blox mode',
	);

	static paymentCardToken = new CheckoutType(
		undefined,
		'Checkout to create a payment card token',
	);

	static assignable = [
		CheckoutType.external,
		CheckoutType.regular,
		CheckoutType.paymentLink,
		CheckoutType.blox,
		CheckoutType.paymentCardToken,
	];

	get isRegular(): boolean {
		return CheckoutType.regular === this;
	}

	get isPaywith(): boolean {
		return CheckoutType.paywith === this;
	}

	get isPaymentLink(): boolean {
		return CheckoutType.paymentLink === this;
	}

	get isBlox(): boolean {
		return CheckoutType.blox === this;
	}

	get isPaymentCardToken(): boolean {
		return CheckoutType.paymentCardToken === this;
	}

	get isVirtualTerminal(): boolean {
		return CheckoutType.virtualTerminal === this;
	}

}
