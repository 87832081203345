import { UrlSegment, UrlSegmentGroup, Route, UrlMatchResult } from '@angular/router';

import { Dictionary } from '@bp/shared/typings';

import { getVersionSegmentFromUrl } from './get-version-segment-from-url';

export function matchRoutePathWithVersionPathSegment(path: string) {
	return (segments: UrlSegment[], _segmentGroup: UrlSegmentGroup, _route: Route): UrlMatchResult | null => {
		const isFirstSegmentIsVersion = !!getVersionSegmentFromUrl(`/${ segments[0]?.path }`);

		const segmentsToParse = isFirstSegmentIsVersion ? segments.slice(1) : segments;

		// Code below is copied from the default matcher `defaultUrlMatcher` from `@angular/router`

		const parts = path === '' ? [] : path.split('/');

		if (parts.length > segmentsToParse.length) {
			// The actual URL is shorter than the config, no match
			return null;
		}

		const posParams: Dictionary<UrlSegment> = {};

		// Check each config part against the actual URL
		for (const [ index, part ] of parts.entries()) {
			const segment = segmentsToParse[index];
			const isParameter = part.startsWith(':');

			if (isParameter)
				posParams[part.slice(1)] = segment;
			else if (part !== segment.path) {
				// The actual URL part does not match the config, no match
				return null;
			}
		}

		const consumed = segmentsToParse.slice(0, parts.length);

		if (isFirstSegmentIsVersion)
			consumed.unshift(segments[0]);

		return { consumed, posParams };
	};
}
