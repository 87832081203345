import { GetEnumerationLiterals } from '@bp/shared/models/core/enum';
import { CamelToSnakeCaseLiteral } from '@bp/shared/typings';

import { PspPaymentOptionType } from './psp-payment-option-type';

export type PaymentOptionTypeLiterals = GetEnumerationLiterals<typeof PaymentOptionType>;

export type PaymentOptionTypeSnakeCaseLiterals = Exclude<CamelToSnakeCaseLiteral<PaymentOptionTypeLiterals>, 'assignable' | 'static_option' | 'static_options'>;

export class PaymentOptionType extends PspPaymentOptionType {

	static wireTransfer = new PaymentOptionType('Bank wire details', 'A set of bank account requisites per currency');

	static externalLink = new PaymentOptionType(undefined, 'A custom link to any internet resource');

	static cryptoWallet = new PaymentOptionType('Crypto wallets', 'A set of crypto currency addresses');

	static staticOptions = [
		PaymentOptionType.externalLink,
		PaymentOptionType.wireTransfer,
		PaymentOptionType.cryptoWallet,
	];

	static override assignable = [
		PaymentOptionType.creditCard,
		PaymentOptionType.apm,
		PaymentOptionType.walletApplePay,
		PaymentOptionType.walletGooglePay,
		PaymentOptionType.wallet,
		PaymentOptionType.external,
		PaymentOptionType.pos,
		PaymentOptionType.crypto,
		...PaymentOptionType.staticOptions,
	];

	static staticOption = new PaymentOptionType('Static options');

	static isStatic(type: PaymentOptionType): boolean {
		return PaymentOptionType.staticOptions.includes(type);
	}

}
