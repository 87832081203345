/* eslint-disable no-await-in-loop */
/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export async function getValidImageUrl(imageSourcePath: string) {
	const hasExtension = (/(\.[^/\\]+)$/u).test(imageSourcePath);

	return new Promise<string | false>(async resolve => {
		const checkIfExistsAndResolve = async (url: string) => {
			const exists = await isImageExist(url);

			resolve(exists ? url : false);
		};

		if (hasExtension)
			await checkIfExistsAndResolve(imageSourcePath);
		else {
			const imageExtensions = [ 'svg', 'gif', 'png' ];

			for (const imageExtension of imageExtensions) {
				const imageSource = `${ imageSourcePath }.${ imageExtension }`;
				const exist = await isImageExist(imageSource);

				if (exist) {
					resolve(imageSource);

					return;
				}
			}

			resolve(false);
		}
	});
}

async function isImageExist(url: string) {
	const img = new Image();

	img.src = url;

	return new Promise<string | null>(resolve => {
		img.addEventListener('load', () => void resolve(img.height === 0 ? null : url));

		img.addEventListener('error', () => void resolve(null));
	});
}
