import { IAppVersion } from '@bp/shared/typings';

export class AppVersion implements IAppVersion {

	readonly release: string;

	readonly releaseName: string | null;

	readonly shortSHA: string | null;

	readonly releaseTitle: string;

	constructor({
		release,
		releaseName,
		shortSHA,
	}: {
		release: string;
		releaseName: string | null;
		shortSHA: string | null;
	}) {
		this.release = release;

		this.releaseName = releaseName;

		this.shortSHA = shortSHA;

		this.releaseTitle = this.shortSHA
			? `${ this.release }-${ this.releaseName }-${ this.shortSHA }`
			: this.release;
	}

}
