import type { DTO } from '@bp/shared/models/metadata';
import { isEmpty, tryDecodeBase64 } from '@bp/shared/utilities/core';

import type { IRouteStaticPaymentOptionExternalLink } from './route-static-payment-option-external-link.interface';

export function externalLinkJsonPropertyDtoSourceMapper(jsonValues: string[]): DTO<IRouteStaticPaymentOptionExternalLink> {
	if (isEmpty(jsonValues))
		return {};

	return JSON.parse(tryDecodeBase64(jsonValues[0]));
}
