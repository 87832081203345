import { Default, DTO, MapFromDTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';
import { PaymentCardBrand, PaymentCardsUtils } from '@bp/shared/domains/payment-cards';

export class SavedPaymentCardToken extends MetadataEntity {

	@MapFromDTO()
	readonly token!: string;

	@Default('')
	readonly holder!: string;

	@Mapper((number: string) => PaymentCardsUtils.formatMaskedCardNumber(number))
	readonly number!: string;

	@MapFromDTO()
	readonly expireMonth!: number;

	@MapFromDTO()
	readonly expireYear!: number;

	/**
	 * MM / YY
	 */
	readonly expirationDate: string;

	readonly brand: PaymentCardBrand | null;

	constructor(dto: DTO<SavedPaymentCardToken>) {
		super(dto);

		this.expirationDate = PaymentCardsUtils.toExpireDateString(this.expireMonth, this.expireYear);

		this.brand = PaymentCardsUtils.guessBestMatchingBrandByCardNumber(this.number.slice(0, 6));

	}

}
