import { Enumeration } from '@bp/shared/models/core/enum';

export class ApmPaymentOptionSubType extends Enumeration {

	static regular = new ApmPaymentOptionSubType();

	static eft = new ApmPaymentOptionSubType();

	static crypto = new ApmPaymentOptionSubType();

	get isEft(): boolean {
		return this === ApmPaymentOptionSubType.eft;
	}

}
