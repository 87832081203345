export function documentWrite(htmlDocument: Document, html: string): void {
	htmlDocument.open();

	try {
		htmlDocument.write(html);
	} catch {
		// just skip internal iframe errors like absent resources and etc
	}

	htmlDocument.close();
}
