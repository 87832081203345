
const TRUST_LOGOS_ASSETS_DIR = '/assets/trust-logos';

export type ImgDefinition = {
	src: string;
	alt: string;
	svg: boolean;
};

const VERIFIED_BY_VISA_LOGO: ImgDefinition = {
	src: `${ TRUST_LOGOS_ASSETS_DIR }/verified-by-visa`,
	alt: 'Verified by Visa logo',
	svg: true,
};

const MASTERCARD_SECURECODE_LOGO: ImgDefinition = {
	src: `${ TRUST_LOGOS_ASSETS_DIR }/master-card`,
	alt: 'MasterCard SecureCode logo',
	svg: true,
};

const VISA_SECURE_LOGO: ImgDefinition = {
	src: `${ TRUST_LOGOS_ASSETS_DIR }/visa-secure`,
	alt: 'Visa Secure logo',
	svg: true,
};

const VISA_SECURE_WHITE_LOGO: ImgDefinition = {
	src: `${ TRUST_LOGOS_ASSETS_DIR }/visa-secure-white`,
	alt: 'Visa Secure logo',
	svg: true,
};

const PCI_DSS_LOGO: ImgDefinition = {
	src: `${ TRUST_LOGOS_ASSETS_DIR }/pci-dss`,
	alt: 'PCI DSS logo',
	svg: true,
};

const SSL_SECURE_LOGO: ImgDefinition = {
	src: `${ TRUST_LOGOS_ASSETS_DIR }/ssl-secure`,
	alt: 'SSL Secure logo',
	svg: true,
};

export const TRUST_LOGOS_FOR_DARK_BLUE_BG = [
	VERIFIED_BY_VISA_LOGO,
	MASTERCARD_SECURECODE_LOGO,
	VISA_SECURE_LOGO,
	PCI_DSS_LOGO,
	SSL_SECURE_LOGO,
];

export const TRUST_LOGOS_FOR_WHITE_BG = [
	VERIFIED_BY_VISA_LOGO,
	MASTERCARD_SECURECODE_LOGO,
	VISA_SECURE_WHITE_LOGO,
	PCI_DSS_LOGO,
	SSL_SECURE_LOGO,
];
