import { isObject, isString, isNil } from 'lodash-es';

import { Countries, CountryCode, Country } from '@bp/shared/models/countries';
import { PrimitiveConstructable } from '@bp/shared/models/core';
import { NonFunctionProperties } from '@bp/shared/typings';

export class CheckoutLanguage extends PrimitiveConstructable {

	readonly displayCode?: string;

	readonly iso!: string;

	readonly country!: Country;

	readonly name!: string;

	readonly isRightToLeft?: boolean;

	readonly lowerCaseName!: string;

	constructor(dtoOrIso: Partial<NonFunctionProperties<CheckoutLanguage>> | string) {
		super(isString(dtoOrIso) ? dtoOrIso : dtoOrIso.iso!);

		if (this._isCached())
			return this;

		if (isObject(dtoOrIso))
			Object.assign(this, dtoOrIso);

		this.lowerCaseName = this.name.toLowerCase();

		if (isNil(this.country))
			this.country = Countries.get(<CountryCode> this.iso.toUpperCase())!;

		this._cacheAndFreezeInstance();
	}

	protected _getScope(): string {
		return 'checkout_language';
	}

}

export class CheckoutLanguages {
	static list = [
		new CheckoutLanguage({
			iso: 'en',
			name: 'English',
			country: Countries.get('GB'),
		}),
		new CheckoutLanguage({
			iso: 'zh',
			name: 'Chinese Simplified (汉语)',
			country: Countries.get('CN'),
		}),
		new CheckoutLanguage({
			iso: 'zh-TW',
			name: 'Chinese Traditional (漢語)',
			country: Countries.get('CN'),
		}),
		new CheckoutLanguage({
			iso: 'ar',
			name: 'Arabic (العَرَبِيَّة)',
			country: Countries.get('AE'),
			isRightToLeft: true,
		}),
		new CheckoutLanguage({
			iso: 'ms',
			name: 'Malay (بهاس ملايو)',
			country: Countries.get('MY'),
		}),
		new CheckoutLanguage({
			iso: 'ja',
			name: 'Japanese (日本語)',
			country: Countries.get('JP'),
		}),
		new CheckoutLanguage({
			iso: 'el',
			name: 'Greek (Ελληνική)',
			country: Countries.get('GR'),
		}),
		new CheckoutLanguage({
			iso: 'ko',
			name: 'Korean (한국어)',
			country: Countries.get('KR'),
		}),
		new CheckoutLanguage({
			iso: 'sr',
			name: 'Serbian (Srpski)',
			country: Countries.get('RS'),
		}),
		new CheckoutLanguage({
			iso: 'sq',
			name: 'Albanian',
			country: Countries.get('AL'),
		}),
		new CheckoutLanguage({
			iso: 'iw',
			displayCode: 'he',
			name: 'Hebrew (עברית)',
			country: Countries.get('IL'),
			isRightToLeft: true,
		}),
		new CheckoutLanguage({
			iso: 'sv',
			name: 'Swedish (Svenska)',
			country: Countries.get('SE'),
		}),
		new CheckoutLanguage({
			iso: 'cs',
			name: 'Czech (Čeština)',
			country: Countries.get('CZ'),
		}),
		new CheckoutLanguage({
			iso: 'sr-ME',
			name: 'Montenegrin (Crnogorski)',
			country: Countries.get('ME'),
		}),
		new CheckoutLanguage({ iso: 'hu', name: 'Hungarian (Magyar)' }),
		new CheckoutLanguage({ iso: 'no', name: 'Norwegian (Norsk)' }),
		new CheckoutLanguage({ iso: 'ro', name: 'Romanian (Română)' }),
		new CheckoutLanguage({ iso: 'it', name: 'Italian (Italiano)' }),
		new CheckoutLanguage({ iso: 'fi', name: 'Finnish (Suomi)' }),
		new CheckoutLanguage({ iso: 'pl', name: 'Polish (Polski)' }),
		new CheckoutLanguage({ iso: 'fr', name: 'French (Français)' }),
		new CheckoutLanguage({ iso: 'de', name: 'German (Deutsche)' }),
		new CheckoutLanguage({ iso: 'es', name: 'Spanish (Español)' }),
		new CheckoutLanguage({ iso: 'ru', name: 'Russian (Русский)' }),
		new CheckoutLanguage({ iso: 'pt', name: 'Portuguese (Português)' }),
	];

	private static readonly __langByIsoCodeMap = new Map<string, CheckoutLanguage>(CheckoutLanguages.list
		.map(it => <[ string, CheckoutLanguage ]>[ it.iso.toLowerCase(), it ]));

	private static readonly __langNames = CheckoutLanguages.list.map(v => v.lowerCaseName);

	static findByLangName(langName: string): CheckoutLanguage | null {
		const lowerCaseLangName = langName.toLowerCase();

		return CheckoutLanguages.list.find(v => v.lowerCaseName === lowerCaseLangName) ?? null;
	}

	static findByIso(iso: string): CheckoutLanguage | null {
		return CheckoutLanguages.__langByIsoCodeMap.get(iso.toLowerCase()) ?? null;
	}

	static includes(langName: string): boolean {
		return CheckoutLanguages.__langNames.includes(langName.toLowerCase());
	}

	static includesIso(iso: string): boolean {
		return CheckoutLanguages.list.some(v => v.iso === iso);
	}
}
