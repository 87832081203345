/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FormControl } from '@angular/forms';

import { IValidatorFunc, Validators } from '@bp/shared/features/validation/models';

export class ValidatableProperty<T = string | undefined> {
	get valid() {
		return this.control.valid;
	}

	get invalid() {
		return !this.valid;
	}

	get value(): T {
		return <any> this.raw;
	}

	get validator() {
		return this._validator;
	}

	raw!: string;

	private readonly control = new FormControl('', [ ...this.validators, ...this.onSetValidators ]);

	private readonly _validator = Validators.compose(this.validators);

	constructor(
		private readonly validators: IValidatorFunc[] = [],
		private readonly onSetValidators: IValidatorFunc[] = [],
	) { }

	set(raw: string) {
		this.control.setValue(raw);

		this.raw = raw;
	}

	toJSON() {
		return this.value;
	}
}
