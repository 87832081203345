import { Enumeration, GetEnumerationLiterals } from '@bp/shared/models/core/enum';

import { IBloxConfiguration } from '../blox-configuration.interface';

import { EventPayload } from './event-payload.type';

export type BloxHostEventLiterals = GetEnumerationLiterals<typeof BloxHostEvent>;

export class BloxHostEvent<T extends EventPayload> extends Enumeration {

	static setConfig = new BloxHostEvent<IBloxConfiguration>();

	static setPaymentCardBrand = new BloxHostEvent<{ cardBrand: string }>();

	static clear = new BloxHostEvent();

	static validate = new BloxHostEvent();

	payloadSchema!: T;

}
