import { Enumeration } from '@bp/shared/models/core/enum';

export class CheckoutLaunchButtonMode extends Enumeration {

	static modal = new CheckoutLaunchButtonMode();

	static tab = new CheckoutLaunchButtonMode();

	static spot = new CheckoutLaunchButtonMode();

	static wallet = new CheckoutLaunchButtonMode();

	static override parseHook = (value: unknown): CheckoutLaunchButtonMode | null => {
		if (value === 'browserCheckout')
			return CheckoutLaunchButtonMode.wallet;

		return null;
	};

	get isWallet(): boolean {
		return this === CheckoutLaunchButtonMode.wallet;
	}
}
