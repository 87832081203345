import { Enumeration } from '@bp/shared/models/core/enum';

export class CheckoutEnvironment extends Enumeration {

	static development = new CheckoutEnvironment();

	static staging = new CheckoutEnvironment();

	static prerelease = new CheckoutEnvironment();

	static production = new CheckoutEnvironment();

	static override parseHook = (value: unknown): CheckoutEnvironment | null => {
		if (value === 'bridgeros')
			return CheckoutEnvironment.prerelease;

		if (value === 'productionV2')
			return CheckoutEnvironment.production;

		if (value === 'next' || value === 'stagingV2' || value === 'sandbox')
			return CheckoutEnvironment.staging;

		return null;
	};
}
