import { Enumeration } from '@bp/shared/models/core/enum';

export class CheckoutTheme extends Enumeration {

	static dark = new CheckoutTheme();

	static light = new CheckoutTheme();

	static bright = new CheckoutTheme();

	static admin = new CheckoutTheme();

	static transparent = new CheckoutTheme();

	static override parseHook(value: unknown): CheckoutTheme | null {
		// Bullshit value coming from backend, we don't have such theme
		if (`${ value }`.toLowerCase() === 'none')
			return CheckoutTheme.light;

		return null;
	}

	get isDark(): boolean {
		return this === CheckoutTheme.dark;
	}

	get isLight(): boolean {
		return this === CheckoutTheme.light;
	}

	get isBright(): boolean {
		return this === CheckoutTheme.bright;
	}

	protected override _init(): void {
		super._init();

		this.cssClass = `${ this.name }-theme`;
	}
}
