import { Enumeration } from '@bp/shared/models/core/enum';

export class CheckoutMode extends Enumeration {

	static embedded = new CheckoutMode();

	static mpi = new CheckoutMode();

	static standalone = new CheckoutMode();

}
