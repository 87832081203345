import { environment } from '@bp/frontend/environments';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import { RequestDeposit } from '../models/payment-request';

export function logNonCriticalPaymentRequestProperties(
	origin: string,
	payment?: RequestDeposit,
): void {
	if (payment && 'card_holder_name' in payment && environment.isProduction) {
		TelemetryService.log(
			origin,
			{
				type: payment.type,
				provider: payment.provider,
				amount: payment.amount,
				currency: payment.currency,
				bin: getMaskedCardNumber(payment),
				paymentId: payment.payment_id,
				paymentMethodInstanceId: payment.payment_method_instance_id,
			},
		);
	} else {
		TelemetryService.log(
			origin,
			payment,
		);
	}
}

function getMaskedCardNumber(payment: RequestDeposit): string | null {
	if ('credit_card_number' in payment) {
		const ccNumber = `${ payment.credit_card_number }`;

		return `${ ccNumber.slice(0, 6) } **** ${ ccNumber.slice(-4) }`;
	}

	return null;
}
