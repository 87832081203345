import { camelCase, mapKeys } from 'lodash-es';

import type { DTO } from '@bp/shared/models/metadata';
import { isEmpty, tryDecodeBase64 } from '@bp/shared/utilities/core';

import type { BankAccountRequisites } from './bank-account-requisites';
import type { IRouteStaticPaymentOptionWireTransfer } from './route-static-payment-option-wire-transfer.interface';

export function wireTransferJsonPropertyDtoSourceMapper(jsonValues: string[]): DTO<IRouteStaticPaymentOptionWireTransfer> {
	if (isEmpty(jsonValues))
		return {};

	const dtos = jsonValues.map((dto): DTO<BankAccountRequisites> | DTO<IRouteStaticPaymentOptionWireTransfer> => JSON.parse(
		tryDecodeBase64(dto),
	));

	if (isEmpty(dtos))
		return {};

	const paymentOptionProperty: keyof IRouteStaticPaymentOptionWireTransfer = 'requisites';
	const [ possiblePaymentOption ] = dtos;

	if (paymentOptionProperty in possiblePaymentOption)
		return possiblePaymentOption;

	return {
		requisites: dtos.map(dto => mapKeys(dto, (_v, k) => camelCase(k))),
	};
}
