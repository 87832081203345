
import { environmentExtender } from './environment.extender';

export const environment = environmentExtender({

	name: 'production',

	checkoutLauncherUrl: 'https://checkout.{{mainDomain}}/v2/launcher',

	googleTagTransportUrl: 'https://analytics.bridgerpay.com',

	bridgerAdminOrigin: 'https://console.bridgerpay.com',

	merchantAdminOrigin: 'https://dash.bridgerpay.com',

	api: 'relative',

	realUserMonitoring: false,

});
