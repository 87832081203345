export const INTERNAL_PROPERTY_NAME_PREFIX = '$$';

export const INTERNAL_QUERY_PARAM_NAME_PREFIX = '__';

export function mapInternalPropertyNameToQueryParamName(key: string): string {
	return key.replace(
		INTERNAL_PROPERTY_NAME_PREFIX,
		INTERNAL_QUERY_PARAM_NAME_PREFIX,
	);
}

export function mapInternalQueryParamNameToInternalPropertyName(key: string): string {
	return key.replace(
		INTERNAL_QUERY_PARAM_NAME_PREFIX,
		`${ INTERNAL_PROPERTY_NAME_PREFIX }${ INTERNAL_PROPERTY_NAME_PREFIX }`, // $ in a replace value denotes special replacement patterns, so $$$$ => $$
	);
}
