import { camelCase, mapKeys } from 'lodash-es';

import type { DTO } from '@bp/shared/models/metadata';
import { isEmpty, tryDecodeBase64 } from '@bp/shared/utilities/core';

import type { CryptoWalletLegacyDTO } from './crypto-wallet';
import { CryptoWallet } from './crypto-wallet';
import type { IRouteStaticPaymentOptionCryptoWallets } from './route-static-payment-option-crypto-wallets.interface';

export function cryptoWalletsJsonPropertyDtoSourceMapper(jsonValues: string[]): DTO<IRouteStaticPaymentOptionCryptoWallets> {
	if (isEmpty(jsonValues))
		return {};

	const dtos = jsonValues.map((dto): CryptoWalletLegacyDTO | DTO<IRouteStaticPaymentOptionCryptoWallets> => JSON.parse(
		tryDecodeBase64(dto),
	));

	if (isEmpty(dtos))
		return {};

	const paymentOptionProperty: keyof IRouteStaticPaymentOptionCryptoWallets = 'wallets';
	const [ possiblePaymentOption ] = dtos;

	if (paymentOptionProperty in possiblePaymentOption)
		return possiblePaymentOption;

	return {
		wallets: dtos
			.map(dto => mapKeys(dto, (_v, k) => camelCase(k)))
			.map(dto => CryptoWallet.fromLegacy(dto)!),
	};
}
