import { Enumeration } from '@bp/shared/models/core/enum';
import { bpQueueMicrotask } from '@bp/shared/utilities/core';

export class TransactionStatus extends Enumeration {

	/**
	 * When the back starts sending bullshit
	 */
	static readonly unknown = new TransactionStatus();

	static readonly init = new TransactionStatus();

	static readonly approved = new TransactionStatus();

	static readonly approvedOnHold = new TransactionStatus();

	static readonly authorized = new TransactionStatus();

	static readonly inProcess = new TransactionStatus();

	static readonly pending = new TransactionStatus();

	static readonly captureInProgress = new TransactionStatus();

	static readonly refunded = new TransactionStatus();

	static readonly partlyRefunded = new TransactionStatus();

	static readonly voided = new TransactionStatus();

	static readonly declined = new TransactionStatus();

	static readonly declinedDueTo3DAuthFailure = new TransactionStatus();

	static readonly declinedDoNotTryAgain = new TransactionStatus();

	static readonly declinedTryAgain = new TransactionStatus();

	static readonly declinedByTimeout = new TransactionStatus();

	static readonly declinedDueToInvalidCreditCard = new TransactionStatus();

	static readonly declinedDueToInvalidData = new TransactionStatus();

	static readonly declinedDueToError = new TransactionStatus();

	static readonly filteredByRule = new TransactionStatus();

	static readonly skippedDueToMissingFingerprint = new TransactionStatus();

	static readonly threeDsInit = new TransactionStatus('3DS Initialization');

	static readonly threeDsInitFailed = new TransactionStatus('3DS Initialization failed');

	static readonly threeDsAuthenticated = new TransactionStatus('3DS Authenticated');

	static readonly threeDsNotAuthenticated = new TransactionStatus('3DS Not authenticated');

	static readonly threeDsFailed = new TransactionStatus('3DS Authentication failed');

	static paymentRelated: TransactionStatus[];

	static refundRelated: TransactionStatus[];

	static payoutRelated: TransactionStatus[];

	static threeDsRelated: TransactionStatus[];

	static {
		this.paymentRelated = [
			this.init,
			this.approved,
			this.approvedOnHold,
			this.authorized,
			this.inProcess,
			this.pending,
			this.captureInProgress,
			this.refunded,
			this.partlyRefunded,
			this.voided,
			this.declined,
			this.declinedDueTo3DAuthFailure,
			this.declinedDueToInvalidData,
			this.declinedByTimeout,
			this.declinedDueToError,
			this.declinedDueToInvalidCreditCard,
			this.filteredByRule,
			this.skippedDueToMissingFingerprint,
		];

		this.payoutRelated = [
			this.init,
			this.declinedByTimeout,
			this.approved,
			this.declined,
			this.inProcess,
			this.pending,
		];

		this.refundRelated = [
			this.approved,
			this.approvedOnHold,
			this.inProcess,
			this.pending,
			this.declined,
			this.declinedByTimeout,
			this.declinedDueToError,
			this.declinedDueToInvalidCreditCard,
		];

		this.threeDsRelated = [
			this.threeDsInit,
			this.threeDsInitFailed,
			this.threeDsAuthenticated,
			this.threeDsNotAuthenticated,
			this.threeDsFailed,
		];
	}

	isApprovedLike!: boolean;

	isPendingLike!: boolean;

	isDeclinedLike!: boolean;

	canBeRechecked!: boolean;

	isVoided!: boolean;

	isCreditCardInProcess!: boolean;

	isDeclinedDueToInvalidCard!: boolean;

	isThreeDsRelated!: boolean;

	constructor(displayName?: string) {
		super(displayName);

		bpQueueMicrotask((): void => {
			this.isApprovedLike = this._checkIsApprovedLike();

			this.isPendingLike = this.__checkIsPendingLike();

			this.isDeclinedLike = this.__checkIsDeclinedLike();

			this.canBeRechecked = this.__checkCanBeRechecked();

			this.isVoided = this === TransactionStatus.voided;

			this.isCreditCardInProcess = this === TransactionStatus.inProcess;

			this.isDeclinedDueToInvalidCard = this === TransactionStatus.declinedDueToInvalidCreditCard;

			this.isThreeDsRelated = this.name.startsWith('threeDs');
		});
	}

	private _checkIsApprovedLike(): boolean {
		return (<TransactionStatus[]>[
			TransactionStatus.approved,
			TransactionStatus.approvedOnHold,
			TransactionStatus.authorized,
		])
			.includes(this);
	}

	private __checkCanBeRechecked(): boolean {
		return (<TransactionStatus[]>[
			TransactionStatus.pending,
			TransactionStatus.inProcess,
			TransactionStatus.captureInProgress,
		])
			.includes(this);
	}

	private __checkIsPendingLike(): boolean {
		return (<TransactionStatus[]>[
			TransactionStatus.init,
			TransactionStatus.pending,
			TransactionStatus.inProcess,
			TransactionStatus.captureInProgress,
			TransactionStatus.threeDsInit,
			TransactionStatus.threeDsAuthenticated,
		])
			.includes(this);
	}

	private __checkIsDeclinedLike(): boolean {
		return (<TransactionStatus[]>[
			TransactionStatus.declined,
			TransactionStatus.declinedByTimeout,
			TransactionStatus.declinedDoNotTryAgain,
			TransactionStatus.declinedDueTo3DAuthFailure,
			TransactionStatus.declinedDueToError,
			TransactionStatus.declinedDueToInvalidCreditCard,
			TransactionStatus.declinedDueToInvalidData,
			TransactionStatus.threeDsInitFailed,
			TransactionStatus.threeDsFailed,
			TransactionStatus.threeDsNotAuthenticated,
		])
			.includes(this);
	}

}
