import { IBaseEnvironmentConfig, IEnvironmentConfig } from '@bp/shared/typings';
import { constants } from '@bp/shared/environments';

import { environment as appEnvironment } from './environment.app';

export function environmentExtender(environment: IBaseEnvironmentConfig): IEnvironmentConfig {
	return {

		...environment,

		/*
		* By putting this always after the main environment config this allows overriding
		* of any environment specific property by the app
		*/
		...appEnvironment,

		...constants,

		isLocal: environment.name === 'local',

		isPrerelease: environment.name === 'prerelease',

		isProduction: environment.name === 'production',

		isDeployed: environment.name !== 'local',

	};
}
