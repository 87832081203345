import { EncryptCvvResponse, PaymentCardToken } from '@bp/shared/domains/payment-card-tokens';
import { Enumeration } from '@bp/shared/models/core/enum';
import { DTO } from '@bp/shared/models/metadata';

import { EventPayload } from './event-payload.type';

export class BloxControllerEvent<T extends EventPayload> extends Enumeration {

	static init = new BloxControllerEvent();

	static initFailed = new BloxControllerEvent<{ error: string }>();

	static paymentCardToken = new BloxControllerEvent<DTO<PaymentCardToken>>();

	static encryptedCvv = new BloxControllerEvent<DTO<EncryptCvvResponse>>();

	static error = new BloxControllerEvent<{ error: any }>();

	payloadSchema!: T;

}
