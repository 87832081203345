import { Enumeration, GetEnumerationLiterals } from '@bp/shared/models/core/enum';

export type BloxTypeLiterals = GetEnumerationLiterals<typeof BloxType>;

export class BloxType extends Enumeration {

	// static card = new BloxType();

	static cardNumber = new BloxType();

	static cardExpiryDate = new BloxType();

	static cardCvv = new BloxType();

	// get isCard(): boolean {
	// 	return this === BloxType.card;
	// }

	get isCardNumber(): boolean {
		return this === BloxType.cardNumber;
	}

	get isCardExpiryDate(): boolean {
		return this === BloxType.cardExpiryDate;
	}

	get isCardCvv(): boolean {
		return this === BloxType.cardCvv;
	}

}
