import { Enumeration, GetEnumerationLiterals } from '@bp/shared/models/core/enum';

import { IBloxControllerConfiguration } from '../blox-configuration.interface';

import { EventPayload } from './event-payload.type';

export type BloxControllerHostEventLiterals = GetEnumerationLiterals<typeof BloxControllerHostEvent>;

export interface IPaymentCardTokenCreateEventPayload {

	cardHolderName: string;

	singleUse?: boolean;

}

export class BloxControllerHostEvent<T extends EventPayload> extends Enumeration {

	static setConfig = new BloxControllerHostEvent<IBloxControllerConfiguration>();

	static createPaymentCardToken = new BloxControllerHostEvent<IPaymentCardTokenCreateEventPayload>();

	static encryptCvv = new BloxControllerHostEvent();

	payloadSchema!: T;

}
